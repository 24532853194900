// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-jsx": () => import("./../src/templates/landing.jsx" /* webpackChunkName: "component---src-templates-landing-jsx" */),
  "component---src-templates-product-root-jsx": () => import("./../src/templates/product-root.jsx" /* webpackChunkName: "component---src-templates-product-root-jsx" */),
  "component---src-templates-product-type-jsx": () => import("./../src/templates/product-type.jsx" /* webpackChunkName: "component---src-templates-product-type-jsx" */),
  "component---src-templates-product-jsx": () => import("./../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-about-jsx": () => import("./../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-single-news-jsx": () => import("./../src/templates/single-news.jsx" /* webpackChunkName: "component---src-templates-single-news-jsx" */)
}

