import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import resourceEn from './locales/en/duro.json'
import resourceZh from './locales/zh/duro.json'

i18n
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    fallbackLng: {
      default: ['en'],
    },
    lng: process.env.SITE_HTML_LANG || 'en',
    // have a common namespace used around the full app
    ns: ['duro'],
    defaultNS: 'duro',
    react: {
      wait: true,
      useSuspense: false,
    },
    detection: {
      order: ['htmlTag'],
    },
  })

i18n.addResourceBundle('en', 'duro', resourceEn)
i18n.addResourceBundle('zh', 'duro', resourceZh)

global.i18n = i18n

export default i18n
